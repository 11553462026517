<template>
  <div v-if="content">
    <section class="banner-area">
      <div class="container pa-0">
        <div class="headerVideo">
          <div class="overlay"></div>
          <video
            playsinline="playsinline"
            autoplay="autoplay"
            muted="muted"
            loop="loop"
          >
            <source
              :src="
                `https://admin.nasto.fr/storage/uploads` +
                getContentValue('main_page_settings', 'banner_video').path
              "
              type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
            />
            <source
              :src="
                `https://admin.nasto.fr/storage/uploads` +
                getContentValue('main_page_settings', 'banner_video2').path
              "
              type='video/webm; codecs="vp8, vorbis"'
            />
            <source
              :src="
                `https://admin.nasto.fr/storage/uploads` +
                getContentValue('main_page_settings', 'banner_video3').path
              "
              type='video/ogg; codecs="theora, vorbis"'
            />
          </video>
          <div class="container h-100">
            <div class="d-flex h-100 text-center align-items-center">
              <div class="w-100 text-white videoText">
                <h3>
                  <i>{{
                    getContentValue("main_page_settings", "banner_text1")
                  }}</i>
                </h3>
                <h3>
                  {{ getContentValue("main_page_settings", "banner_text2") }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="recent-blog-area section-gap">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 pb-0 header-text">
            <h1></h1>
          </div>
        </div>

        <hooper :settings="hooperSettings" style="min-height: 359px">
          <slide
            v-for="(article, key) in getContentValue('latest_news')"
            v-bind:key="key"
          >
            <div class="single-recent-blog text-center">
              <div class="col-md-10 offset-md-1">
                <div class="thumb">
                  <a :href="`/posts/` + article.title_slug" class=""
                    ><img
                      :src="
                        `https://admin.nasto.fr/storage/uploads/` +
                        article.image.path
                      "
                      :alt="article.title"
                      class="f-img img-fluid mx-auto"
                  /></a>
                </div>
              </div>
              <a :href="`/posts/` + article.title_slug" class="">
                <h4>{{ article.title }}</h4>
              </a>
              <p
                class="text-center"
                style="padding-left: 20px; padding-right: 20px"
              >
                {{ article.short }}
              </p>
            </div>
          </slide>

          <hooper-navigation slot="hooper-addons">
            <svg
              slot="hooper-prev"
              class="icon icon-arrowLeft"
              viewBox="0 0 24 24"
              width="40px"
              height="80px"
            >
              <title>Arrow Left</title>
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path
                fill="#1B1464"
                d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"
              ></path>
            </svg>
            <svg
              slot="hooper-next"
              class="icon icon-arrowRight"
              viewBox="0 0 24 24"
              width="40px"
              height="80px"
            >
              <title>Arrow Right</title>
              <path d="M0 0h24v24H0z" fill="none"></path>
              <path
                fill="#1B1464"
                d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"
              ></path>
            </svg>
          </hooper-navigation>
        </hooper>
      </div>
    </section>
    <section class="services-area section-gap">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="menu-content col-lg-7">
            <div class="title text-center">
              <h1 class="mb-10">
                {{
                  getContentValue("main_page_settings", "5_words_block_title")
                }}
              </h1>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            :class="['col-md-6', 'col-lg-' + (key < 3 ? '4' : '6')]"
            v-for="(item, key) in getContentValue('words_about')"
            v-bind:key="key"
          >
            <div class="single-services">
              <span :class="['lnr', `lnr-` + item.icon]"></span>
              <a href="/services" class="">
                <h4 class="fiveWordsTitle">{{ item.title }}</h4>
              </a>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-gap">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="menu-content pb-20 col-lg-10">
            <div class="title">
              <h1 class="mb-20 text-center">
                {{
                  getContentValue("main_page_settings", "expertise_block_title")
                }}
              </h1>
              <hr />
              <p
                v-html="
                  getContentValue(
                    'main_page_settings',
                    'expertise_block_content'
                  )
                "
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-gap services-area">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="menu-content pb-20 col-lg-10">
            <div class="title">
              <h1 class="mb-20 text-center">
                {{
                  getContentValue("main_page_settings", "solutions_block_title")
                }}
              </h1>
              <hr />
              <p
                v-html="
                  getContentValue('main_page_settings', 'solutions_block_text')
                "
              ></p>
              <ul class="unordered-list mt-4">
                <li
                  v-for="(item, key) in getContentValue(
                    'main_page_settings',
                    'solutions_items'
                  )"
                  v-bind:key="key"
                >
                  {{ item.value }}
                </li>
              </ul>
              <div class="row mt-4">
                <a
                  href="/services"
                  class="genric-btn default-border circle arrow"
                >
                  {{ getContentValue("vocabulary", "show_more") }}
                  <span class="lnr lnr-arrow-right"></span
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-gap">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="menu-content pb-20 col-lg-10">
            <div class="title">
              <h1 class="mb-20 text-center">
                {{
                  getContentValue("main_page_settings", "recommandations_title")
                }}
              </h1>
              <p
                v-html="
                  getContentValue(
                    'main_page_settings',
                    'recommandations_content'
                  )
                "
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-gap services-area">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="menu-content pb-20 col-lg-10">
            <div class="title text-center">
              <h1 class="mb-20">
                {{ getContentValue("main_page_settings", "about_block_title") }}
              </h1>
              <hr />
              <p
                v-html="
                  getContentValue('main_page_settings', 'about_block_content')
                "
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { systemMixin } from "../mixins/systemMixin";
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "Home",
  mixins: [systemMixin],
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data: function () {
    return {
      hooperSettings: {
        itemsToShow: 2,
        itemsToSlide: 3,
        wheelControl: false,
        breakpoints: {
          400: {
            itemsToShow: 1,
          },

          600: {
            itemsToShow: 2,
          },
          800: {
            itemsToShow: 3,
          },
        },
      },
    };
  },

  methods: {},
  mounted() {
    this.loadData();
  },
};
</script>
<style scoped>
.hooper {
  height: auto !important;
}
.section-gap h1 {
  color: #1b1464;
}
.fiveWordsTitle {
  color: #1b1464;
}
.fiveWordsTitle:first-letter {
    color: #c1272d;
}
</style>

