<template>
  <section class="contact-page-area section-gap" v-if="content">
    <div class="container">
      <div class="row">
        <div class="embed-responsive embed-responsive-21by9">
          <div id="map-wrap" class="embed-responsive-item">
            <l-map
              ref="mymap"
              v-if="content"
              :zoom="zoom"
              :center="center"
              :options="{ zoomControl: false }"
            >
              <l-tile-layer
                :url="url"
                :attribution="attribution"
              ></l-tile-layer>

              <l-marker :lat-lng="marker"></l-marker>
            </l-map>
          </div>
        </div>
      </div>
      <div class="row mt-30">
        <div class="col-lg-4 d-flex flex-column address-wrap">
          <div class="single-contact-address d-flex flex-row mt-10">
            <div class="icon">
              <span class="lnr lnr-home"></span>
            </div>
            <div class="contact-details">
              <h5>{{ getContentValue("contact_vars", "address_line") }}</h5>
              <p>{{ getContentValue("contact_page", "address") }}</p>
            </div>
          </div>
          <div class="single-contact-address d-flex flex-row mt-10">
            <div class="icon">
              <span class="lnr lnr-phone-handset"></span>
            </div>
            <div class="contact-details">
              <h5>{{ getContentValue("contact_vars", "phone_line") }}</h5>
              <p>{{ getContentValue("contact_page", "phone") }}</p>
            </div>
          </div>
          <div class="single-contact-address d-flex flex-row mt-10">
            <div class="icon">
              <span class="lnr lnr-envelope"></span>
            </div>
            <div class="contact-details">
              <h5>{{ getContentValue("contact_vars", "email_line") }}</h5>
              <p>{{ getContentValue("contact_page", "email") }}</p>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div role="form" class="mt-10">
            <div
              role="alert"
              aria-live="polite"
              class="screen-reader-response"
            ></div>
            <p>
              <span class="your-name"
                ><input
                  type="text"
                  name="your-name"
                  value=""
                  size="40"
                  aria-required="true"
                  v-model="form.name"
                  aria-invalid="false"
                  :placeholder="getContentValue('form_fields', 'name')"
                  class="common-input mb-20 form-control"
              /></span>
              <br />
              <span class="your-email"
                ><input
                  type="email"
                  name="your-email"
                  value=""
                  size="40"
                  v-model="form.email"
                  aria-required="true"
                  aria-invalid="false"
                  :placeholder="getContentValue('form_fields', 'email')"
                  class="common-input mb-20 form-control"
              /></span>
              <br />
              <span class="your-message">
                <textarea
                  name="your-message"
                  cols="40"
                  rows="10"
                  aria-invalid="false"
                  v-model="form.message"
                  :placeholder="getContentValue('form_fields', 'message')"
                  class="common-textarea form-control"
                ></textarea>
              </span>
              <br />

              <button class="genric-btn primary pull-right" @click="send()">
                {{ getContentValue("form_fields", "send") }}
              </button>
              <span class="alert alert-success" role="alert" v-if="showMsg">
                Message was successfully sent!
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { systemMixin } from "../mixins/systemMixin";
import { LMap, LMarker, LTileLayer } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "Contact",
  mixins: [systemMixin],
  data: function () {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
      zoom: 13,
      center: [51.505, -0.159],
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: "",
      marker: [51.505, -0.159],
      showMsg: false,
    };
  },
  components: {
    LMap,
    LMarker,
    LTileLayer,
  },
  metaInfo: {
    title: 'Contact us NASTO'
  },
  methods: {
    getLocation: function () {
      this.center = this.marker = [
        this.getContentValue("contact_page", "location").lat,
        this.getContentValue("contact_page", "location").lng,
      ];
    },
    send: function () {
      this.$http
        .post(
          "https://admin.nasto.fr/api/forms/submit/contact?token=80fdaa260981394c0f5e622eaee0a3",
          { form: this.form }
        )
        .then(function () {
          this.showMsg = true;
        });
    },
  },
  async mounted() {
    await this.loadData();
    console.log(this.content);

    this.getLocation();
  },
};
</script>