<template>
  <div>
    <button type="button" id="mobile-nav-toggle">
      <i class="lnr lnr-menu"></i>
    </button>
    <header id="header" class="header-scrolled">
      <div class="container main-menu">
        <div class="row align-items-center justify-content-between d-flex">
          <div class="logo">
            <a
              href="/"
              aria-current="page"
              class="nuxt-link-exact-active nuxt-link-active"
              ><img src="/img/logo2.svg" alt="" title=""
            /></a>
          </div>
          <div>
            <nav id="nav-menu-container">
              <ul class="nav-menu">
                <li>
                  <a
                    href="/"
                    aria-current="page"
                    :class="
                      $route.name && $route.name.toLowerCase() === 'home'
                        ? 'nuxt-link-exact-active nuxt-link-active'
                        : ''
                    "
                    >{{ getMenuLabel("home") }}</a
                  >
                </li>
                <li>
                  <a
                    href="/news"
                    :class="
                      $route.name &&
                      ($route.name.toLowerCase() === 'news' ||
                        $route.name.toLowerCase() === 'post')
                        ? 'nuxt-link-exact-active nuxt-link-active'
                        : ''
                    "
                    >{{ getMenuLabel("news") }}</a
                  >
                </li>
                <li>
                  <a
                    href="/services"
                    :class="
                      $route.name && $route.name.toLowerCase() === 'services'
                        ? 'nuxt-link-exact-active nuxt-link-active'
                        : ''
                    "
                    >{{ getMenuLabel("services") }}</a
                  >
                </li>
                <li>
                  <a
                    href="/contact"
                    :class="
                      $route.name && $route.name.toLowerCase() === 'contact'
                        ? 'nuxt-link-exact-active nuxt-link-active'
                        : ''
                    "
                    >{{ getMenuLabel("contact") }}</a
                  >
                </li>
                <li class="langs">
                  <span>
                    <a
                      href="/"
                      v-on:click.prevent="changeLang('fr-FR')"
                      class=""
                      v-if="currentLanguage !== 'fr-FR'"
                      >FR</a
                    >
                    <span v-if="currentLanguage === 'fr-FR'" style="">FR</span>
                    <span
                      class="d-sm-none d-md-inline"
                      style="padding: 0px 8px 0px 8px"
                      >/</span
                    >
                    <span
                      ><a
                        v-on:click.prevent="changeLang('en-EN')"
                        class=""
                        href="/"
                        v-if="currentLanguage !== 'en-EN'"
                        >EN</a
                      ></span
                    >
                    <span v-if="currentLanguage === 'en-EN'">EN</span>
                  </span>
                </li>
              </ul>
            </nav>
            <div id="mobile-body-overly"></div>
            <nav id="mobile-nav">
              <ul>
                <li>
                  <a
                    href="/"
                    aria-current="page"
                    class="nuxt-link-exact-active nuxt-link-active"
                    >{{ getMenuLabel("home") }}</a
                  >
                </li>
                <li>
                  <a href="/news" class="">{{ getMenuLabel("news") }}</a>
                </li>
                <li>
                  <a href="/services" class="">{{
                    getMenuLabel("services")
                  }}</a>
                </li>
                <li>
                  <a href="/contact" class="">{{ getMenuLabel("contact") }}</a>
                </li>
                <li class="langs">
                  <a
                    href="/i18n/fr-FR"
                    class=""
                    v-if="currentLanguage !== 'fr-FR'"
                    v-on:click.prevent="changeLang('fr-FR')"
                    style="padding-left: 16px"
                    >FR</a
                  >
                  <span
                    v-if="currentLanguage === 'fr-FR'"
                    style="margin-left: 1px"
                    >FR</span
                  >
                  <span
                    class="d-xs-none d-sm-none d-md-inline"
                    style="margin-left: 3px"
                    >/</span
                  >
                  <span
                    ><a
                      href="/i18n/en-EN"
                      class=""
                      v-if="currentLanguage !== 'en-EN'"
                      v-on:click.prevent="changeLang('en-EN')"
                      style="padding-left: 1px"
                      >EN</a
                    ></span
                  >
                  <span v-if="currentLanguage === 'en-EN'">EN</span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "NASTO",
    // all titles will be injected into this template
    meta: [
      {
        name: "viewport",
        content: "width=device-width,initial-scale=1,shrink-to-fit=no",
      },
      {
        name: "description",
        content: "NASTO - Consulting in Strategy and Change Management",
      },
      {
        rel: "icon",
        type: "icon",
        href: "/assets/img/favicon.ico",
      },
    ],
  },
  data: function () {
    return {
      langs: ["en-EN", "fr-FR"],
      currentLanguage: "",
      menu: {
        "en-EN": {
          home: "Home",
          news: "News",
          services: "Services",
          contact: "Contact",
        },
        "fr-FR": {
          home: "ACCUEIL",
          news: "Actualités",
          services: "Solutions",
          contact: "Contact",
        },
      },
    };
  },
  methods: {
    getCurrentLanguage: function () {
      if (localStorage.getItem("selectedLang")) {
        this.currentLanguage = localStorage.getItem("selectedLang");
        return;
      }
      var userLang = navigator.language || navigator.userLanguage;
      if (!this.langs.includes(userLang)) {
        userLang = "en-EN";
      }
      localStorage.setItem("selectedLang", userLang);
      this.currentLanguage = userLang;
    },
    getMenuLabel: function (key) {
      return this.menu[this.currentLanguage][key]
        ? this.menu[this.currentLanguage][key]
        : "";
    },
    changeLang(lang) {
      localStorage.setItem("selectedLang", lang);
      window.location.reload();
    },
  },
  created() {
    this.getCurrentLanguage();
  },
};
</script>

