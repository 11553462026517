import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import Resource from 'vue-resource'

Vue.use(VueMeta)
Vue.use(Resource)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  
  next()
  if(from.name === 'lang'){
    window.location.reload();
  }
})


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
