<template>
  <section class="post-content-area single-post-area" v-if="post">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 posts-list">
          <div class="single-post row" style="min-height: 600px">
            <div class="col-lg-12">
              <div class="feature-img" style="margin-top: 40px">
                <div class="col-md-6 offset-md-3">
                  <img
                    v-if="post.image"
                    :src="
                      `https://admin.nasto.fr/storage/uploads/` +
                      post.image.path
                    "
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-3 meta-details">
              <div class="user-details row">
                <p class="date col-lg-12 col-md-12 col-6">
                  <a href="#">{{ convertDate(post._created) }}</a>
                  <span class="lnr lnr-calendar-full"></span>
                </p>
              </div>
            </div>
            <div class="col-lg-9 col-md-9">
              <h3 class="mt-20 mb-20 postTitle">
                {{ post.title }}
              </h3>
              <p v-html="post.content"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { systemMixin } from "../mixins/systemMixin";
export default {
  name: "Posts",
  mixins: [systemMixin],
  props: ["slug"],
  data: function () {
    return {
      post: null,
    };
  },
  methods: {
    selectPost() {
      return this.content.news.filter((obj) => {
        return obj.title_slug === this.slug;
      });
    },
    convertDate: function (unixTimestamp) {
      let milliseconds = unixTimestamp * 1000; // 1575909015000
      let dateObject = new Date(milliseconds);
      return dateObject.toLocaleString();
    },
  },
  async mounted() {
    await this.loadData();
    this.post = this.selectPost();
    if (this.post[0]) {
      this.post = this.post[0];
      localStorage.setItem("selectedPost", this.post._id);
    } else {
      if (localStorage.getItem("selectedPost")) {
        this.post = this.content.news.filter((obj) => {
          console.log(obj);

          return obj._id === localStorage.getItem("selectedPost");
        });
        if (this.post[0]) {
          this.post = this.post[0];
        }
      }
    }
  },
};
</script>
<style scoped>
.postTitle {
  color: #1b1464;
}
</style>