export const systemMixin = {
    data: function () {
        return {
            content: null,
            langs: {
                'en-EN': 'en',
                'fr-FR': 'fr'
            }
        }
    },
    methods: {
        getLangFile: function () {
            let selectedLanguage = localStorage.getItem('selectedLang')
            return this.langs[selectedLanguage]
        },
        loadData: function () {
            return new Promise((resolve) => {
                this.$http.get('https://nasto.fr/locales/' + this.getLangFile() + '.json').then(function (response) {
                    this.content = response.data
                    resolve(response.data)
                })
            })
        },
        getContentValue: function (page, key = null) {
            if (!key) {
                return this.content[page] ? this.content[page] : ''
            }
            return this.content[page][key] ? this.content[page][key] : ''
        }
    }

}