<template>
  <section style="min-height: 600px" v-if="content">
    <section class="post-content-area" style="padding-top: 120px">
      <div class="container">
        <h1>{{ getContentValue("links", "news") }}</h1>
        <div class="row">
          <div class="col-lg-12 posts-list">
            <div class="single-post row">
              <div
                class="col-lg-6 mt-5"
                v-for="(post, key) in getContentValue('news')"
                v-bind:key="key"
              >
                <div class="feature-img">
                  <img
                    :src="
                      `https://admin.nasto.fr/storage/uploads/` +
                      post.image.path
                    "
                    :alt="post.title"
                    class="img-fluid"
                  />
                </div>
                <a  :href="`/posts/` + post.title_slug" 
                  ><h3 class="mt-4">{{ post.title }}</h3></a
                >
                <p class="excert mt-2">
                  {{post.short}}
                </p>
                <a
                  :href="`/posts/` + post.title_slug"
                  class="primary-btn"
                  >{{ getContentValue("vocabulary", "show_more") }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { systemMixin } from "../mixins/systemMixin";

export default {
  name: "News",
  mixins: [systemMixin],
  metaInfo: {
    title: 'Our latest news NASTO'
  },
  mounted() {
    this.loadData();
  },
};
</script>
