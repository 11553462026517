<template>
  <section class="post-content-area single-post-area" v-if="content">
    <div class="container">
      <div class="col-lg-12 posts-list">
        <div class="single-post row" style="min-height: 500px">
          <div class="col-lg-12">
            <h4 class="mt-40 mb-20 text-center"></h4>
          </div>
          <div class="col-lg-12"><p v-html="getContentValue('services_page', 'content')"></p></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { systemMixin } from "../mixins/systemMixin";


export default {
  name: "Services",
  mixins: [systemMixin],
  metaInfo: {
    title: 'Our services NASTO'
  },
  mounted() {
    this.loadData();
  },
};
</script>