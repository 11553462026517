import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import News from '../views/News.vue'
import Services from '../views/Services.vue'
import Post from '../views/Post.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/posts/:slug',
    name: 'Post',
    props: true,
    component: Post
  },
  {
    path: '/contact',
    name: 'Contact',
    props: true,
    component: Contact
  },
  {
    path: '/i18n/:language',
    name: 'lang',
    props: true,
    component: () => import('../views/ChangeLanguage.vue')
  }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
